import styled from "styled-components/macro";

import { COLORS, containerWidth, FONTS, WEIGHT } from "configs/styles/common";

export const Title = styled.h3`
  margin: 0 auto;
  color: ${COLORS.DARK.Main};
  font-family: ${FONTS.Title};
  font-weight: ${WEIGHT.bold};
  font-size: 24px;
  line-height: 30px;
  position: absolute;
  top: 2px;
  left: 0;
  right: 0;
  text-align: center;
`;

export const Content = styled.div`
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  position: relative;
  max-width: ${containerWidth()};
`;

export const Video = styled.video`
  width: 100%;
  height: auto;
  margin: 0 auto;
  max-width: 900px;
  padding-top: 60px;
`;

export const MobileContainer = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  gap: 40px;
  height: 100%;
  padding: 0;
  padding-top: 40px;
`;

import React, { lazy, Suspense } from "react";
import styled from "styled-components/macro";

import { Header } from "components/presenters/layouts/Header";
import { Spacer } from "components/presenters/layouts/Spacer";
import { GsapProvider } from "components/features/GsapProvider";
import { SEO } from "components/seo";
import { mobileContainerStyle, QUERIES } from "configs/styles/common";
import { useEventTrack } from "services/hooks/useEventTrack";
import { useIsDeviceType } from "services/hooks/useIsDeviceType";

import { HeroSection } from "./components/HeroSection";
import { SliderWrapper } from "./components/SliderSection";
import { FeaturesSection } from "./components/FeaturesSection";
import { useLCPPreload } from "./hooks/useLCPPreload";

const VideoSection = lazy(() => import("./components/VideoSection"));
const BenefitSection = lazy(() => import("./components/BenefitSection"));
const CompaniesSection = lazy(() => import("./components/CompaniesSection/CompaniesSection"));
const CompaniesSectionWithSlider = lazy(() => import("./components/CompaniesSection/CompaniesSectionWithSlider"));
const ContactUsForm = lazy(() => import("components/blocs/ContactUsForm/ContactUsForm"));
const Footer = lazy(() => import("components/presenters/layouts/Footer/Footer"));

export default function HomePage() {
  const { isMobile, isTablet } = useIsDeviceType();
  useEventTrack("Visit HomePage page");
  useLCPPreload();

  return (
    <>
      <SEO title="GeoX Property Intelligence" titleTemplate="" />
      <Header />
      <HeroSection />
      {isMobile ? (
        <FeaturesSection />
      ) : (
        <GsapProvider>
          <SliderWrapper />
        </GsapProvider>
      )}

      <Suspense fallback={null}>
        <VideoSection />
      </Suspense>

      <Suspense fallback={null}>
        <BenefitSection />
      </Suspense>

      <Suspense fallback={null}>{isTablet ? <CompaniesSectionWithSlider /> : <CompaniesSection />}</Suspense>

      <Spacer space={{ desktop: 60, tablet: 36, mobile: 36 }} />
      <ContactUsWrapper>
        <Suspense fallback={null}>
          <ContactUsForm />
        </Suspense>
      </ContactUsWrapper>
      <Spacer space={{ desktop: 60, tablet: 36, mobile: 36 }} />

      <Suspense fallback={null}>
        <Footer />
      </Suspense>
    </>
  );
}

const ContactUsWrapper = styled.div`
  width: 100%;

  @media ${QUERIES.upToMobile} {
    ${mobileContainerStyle}
  }
`;

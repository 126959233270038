import React, { createContext, useRef, useEffect, useState, useMemo, useContext } from "react";

const GsapContext = createContext();

function getAsyncGsapModule() {
  return Promise.all([import("gsap"), import("gsap/ScrollToPlugin"), import("gsap/ScrollTrigger")]);
}

export function GsapProvider({ children }) {
  const GsapRef = useRef(null);
  const ScrollToRef = useRef(null);
  const ScrollTriggerRef = useRef(null);

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    let isMounted = true;
    getAsyncGsapModule()
      .then(([Gsap, ScrollTo, ScrollTrigger]) => {
        if (isMounted) {
          GsapRef.current = Gsap;
          ScrollToRef.current = ScrollTo;
          ScrollTriggerRef.current = ScrollTrigger;
          setIsLoaded(true);
        }
      })
      .catch((error) => {
        console.error("Error loading GSAP modules:", error);
      });

    return () => {
      isMounted = false;
    };
  }, []);

  const value = useMemo(
    () => ({
      isLoaded,
      Gsap: GsapRef.current,
      ScrollTo: ScrollToRef.current,
      ScrollTrigger: ScrollTriggerRef.current,
    }),
    [isLoaded]
  );

  return <GsapContext.Provider value={value}>{children}</GsapContext.Provider>;
}

export function useGsap() {
  const context = useContext(GsapContext);

  if (!context) {
    throw new Error("useGsap must be used within a GsapProvider");
  }

  return context;
}

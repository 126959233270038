import basicConfig from "../../basicConfig";

export function getUser() {
  return basicConfig
    .createRequest("/customer", "GET")
    .then((res) => res.data.data)
    .catch((error) => Promise.reject(error.response));
}

export function getUserPicture() {
  return basicConfig.createRequest("/customer/profile-picture", "GET").then((res) => res.data.data.profile);
}

export function getUserBasicInfo() {
  return basicConfig.createRequest("/v1/customer/profile/basic", "GET").then((res) => res.data.data);
}

export function updateUserPicture(payload) {
  return basicConfig.createRequest("/customer/profile-picture", "POST", {}, payload, true).then((res) => res.data);
}

export function updateUserInfo(payload) {
  return basicConfig
    .createRequest("/customer", "PATCH", { "content-type": "application/json" }, payload)
    .then((res) => res.data);
}

export function logout() {
  return basicConfig
    .createRequest("/auth/logout", "POST", { "content-type": "application/json" }, {})
    .then((res) => res.data);
}

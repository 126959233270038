import styled from "styled-components";

import { Button } from "components/presenters/common/Button";
import { COLORS, Container as SContainer, FONTS, QUERIES } from "configs/styles/common";

export const HeroSection = styled.section`
  width: 100%;
  height: 500px;
  position: relative;
  padding-top: 50px;
  background-color: rgb(156 156 156 / 36%);

  @media ${QUERIES.upToTablet} {
    height: 581px;
  }

  @media ${QUERIES.upToMobile} {
    height: 530px;
  }
`;

export const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
`;

export const Container = styled(SContainer)`
  @media ${QUERIES.upToMobile} {
    max-width: 350px;
  }
`;

export const Title = styled.h1`
  font-family: Bitter, serif;
  font-weight: 700;
  font-size: 32px;
  line-height: 126.5%;
  color: ${COLORS.LIGHT.White};
  margin-bottom: 40px;

  @media ${QUERIES.upToTablet} {
    font-size: 30px;

    & > br {
      display: none;
    }
  }
`;

export const SubTitle = styled.h2`
  font-size: 18px;
  line-height: 130%;
  color: ${COLORS.LIGHT.White};
  margin-bottom: 60px;

  @media ${QUERIES.upToTablet} {
    font-size: 20px;

    & > br {
      display: none;
    }
  }
`;

export const ContactLink = styled.a`
  &:hover {
    text-decoration: none;
  }
`;

export const ContactButton = styled(Button)`
  max-width: 365px;
  height: 46px;
  font-family: ${FONTS.Title};

  @media ${QUERIES.upToTablet} {
    width: 100%;
    height: 54px;
    font-size: 20px;
  }
`;

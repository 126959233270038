import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import { S3_BASE_URL } from "services/constants/externalResources";

import * as Styled from "./style";

const mobile_house_video = S3_BASE_URL + "assets/mobile_house_video_2.mp4";
const mobile_house_video_2 = S3_BASE_URL + "assets/mobile_house_video_2.mp4";

export const FeaturesSection = memo(function FeaturesSection() {
  const { t } = useTranslation("HomePage");

  return (
    <Styled.MobileContainer>
      <Styled.Content>
        <Styled.Title>{t("Residential Property")}</Styled.Title>
        <Styled.Video autoPlay loop muted id="house_video" playsInline preload="auto">
          <source src={mobile_house_video} type="video/mp4" />
        </Styled.Video>
      </Styled.Content>

      <Styled.Content>
        <Styled.Title>{t("Commercial Property")}</Styled.Title>
        <Styled.Video autoPlay loop muted id="house_video_2" playsInline preload="none" fetchPriority="low">
          <source src={mobile_house_video_2} type="video/mp4" />
        </Styled.Video>
      </Styled.Content>
    </Styled.MobileContainer>
  );
});

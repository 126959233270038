import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import API from "api/apiClient";
import { refreshToken } from "api/basicConfig";
import { PopupModal } from "components/_modals/AlertModals";
import { Modal } from "components/presenters/common/Modal";

import { NotificationList, NotificationButton } from "../components";
import * as Styled from "./style";

export function Notification() {
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [isNewNotification, setIsNewNotification] = useState(false);
  const [newPopup, setNewPopup] = useState(false);
  const [isErrorModalShown, setIsErrorModalShown] = useState(false);
  const [data, setData] = useState({});

  const location = useLocation();

  const handleButtonClick = () => {
    setIsVisible((prevState) => !prevState);
  };

  useEffect(() => {
    const source = new EventSource("/api/push-notifications", {
      withCredentials: true,
    });

    source.addEventListener("unauthorized", function () {
      refreshToken()
        .then((data) => {
          if (data.status !== 200) {
            source.close();
          }
        })
        .catch(() => source.close());
    });

    source.addEventListener("new_notification_available", function (event) {
      const newNotificationAvailable = JSON.parse(event.data);

      if (newNotificationAvailable !== isNewNotification) {
        setIsNewNotification(newNotificationAvailable);
      }
    });

    source.addEventListener("new_popup_available", function (event) {
      const newPopupAvailable = JSON.parse(event.data);

      if (newPopupAvailable !== newPopup) {
        setNewPopup(newPopupAvailable);

        API.fetchPopup()
          .then(async (data) => {
            return JSON.parse(await data.text()).data.popup;
          })
          .then((data) => {
            setData(data);
            if (Object.keys(data || {})?.length !== 0) setIsErrorModalShown(true);
          });
      }
    });

    source.addEventListener("error", () => {
      source.close();
    });

    return () => {
      source.close();
    };
  }, [isNewNotification, isErrorModalShown, location, newPopup]);

  function onModalClose() {
    setIsErrorModalShown(false);
    API.markPopupFinished(data?.id);
  }

  return (
    <>
      <Styled.Wrapper>
        <NotificationButton
          setIsLoading={setIsLoading}
          setIsNewNotification={setIsNewNotification}
          isNewNotification={isNewNotification}
          onclick={handleButtonClick}
          visible={isVisible}
          setNotifications={setNotifications}
        />
        {isVisible && (
          <>
            <NotificationList isLoading={isLoading} notifications={notifications} setIsVisible={setIsVisible} />
            <Styled.Triangle />
          </>
        )}
      </Styled.Wrapper>

      <Modal
        open={isErrorModalShown}
        content={PopupModal}
        close={onModalClose}
        title={data?.notification_title}
        description={data?.notification_description}
        modalWidth="30%"
      />
    </>
  );
}

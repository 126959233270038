import React, { createContext, useState, useContext, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import { startTracker, stopTracker, tracker } from "services/tracking/openreplayTracker";
import { showErrorToast, showSuccessToast } from "services/utils/toast";
import API from "api/apiClient";
import { Mixpanel } from "services/tracking/Mixpanel";
import { WelcomeAnimation } from "configs/styles/Authorization/WelcomeAnimation";
import { useStorage } from "services/hooks/useStorage";

import { ROUTE_PATHS } from "../router";

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const { t } = useTranslation("auth");
  const [user, setUser] = useState(null);
  const [isSignedIn, setIsSignedIn] = useStorage("isSignedIn", false);
  const [isAdmin, setIsAdmin] = useStorage("isAdmin", false);

  const history = useHistory();
  const { state } = useLocation();

  const handleInvalidSession = useCallback(() => {
    setIsSignedIn(false);
    setUser(null);
    setIsAdmin(false);
    stopTracker();
  }, [setIsSignedIn, setUser, setIsAdmin]);

  const checkAdminStatus = useCallback(async () => {
    try {
      await API.adminContactUs();
      setIsAdmin(true);
    } catch (error) {
      setIsAdmin(false);
    }
  }, [setIsAdmin]);

  useEffect(() => {
    async function checkSession() {
      if (isSignedIn) {
        try {
          const response = await API.getUser();
          if (!response) {
            handleInvalidSession();
          } else {
            setUser(response);
            await checkAdminStatus();
          }
        } catch (error) {
          handleInvalidSession();
        }
      }
    }

    checkSession();
  }, [isSignedIn, handleInvalidSession, checkAdminStatus]);

  function afterSignIn() {
    WelcomeAnimation();
    history.push(state?.from || ROUTE_PATHS.SINGLE_SEARCH);
  }

  async function signInAction(data) {
    try {
      await API.login(data.email, data.password);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: "sign_in" });

      Mixpanel.identify(data.email);
      Mixpanel.track("Successful regular login");
      Mixpanel.people.set({
        $email: data.email,
      });

      const response = await API.getUser();

      if (!response) return;

      if (!tracker && !isAdmin) {
        await startTracker({
          userID: response.customer_id,
          metadata: {
            country: response.country,
            plan: response.curr_data_plan,
            credits: response.curr_data_plan_credits,
          },
        });
      }
      setIsSignedIn(true);
      setUser(response);
      await checkAdminStatus();
      afterSignIn();
    } catch (error) {
      Mixpanel.reset();
      Mixpanel.track("Unsuccessful regular login", { "From email": data.email });
      throw new Error(error?.response?.data?.msg);
    }
  }

  async function signOutAction() {
    try {
      await API.logout();
      showSuccessToast(t("You are sign out successfully!"));
      setUser(null);
      setIsSignedIn(false);
      setIsAdmin(false);
      stopTracker();
      history.push(ROUTE_PATHS.SIGN_IN);
    } catch (error) {
      showErrorToast(t("Something went wrong!"));
    }
  }

  return (
    <AuthContext.Provider value={{ user, isSignedIn, setIsSignedIn, isAdmin, setIsAdmin, signInAction, signOutAction }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}

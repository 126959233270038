import React from "react";
import { Helmet } from "react-helmet";

import { defaultDescription } from "./constants";

export function SEO({ title, description = defaultDescription, titleTemplate = "GeoX - %s" }) {
  return (
    <Helmet titleTemplate={titleTemplate} defaultTitle="GeoX">
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
}

import { z } from "zod";

function createEnv() {
  const envSchema = z.object({
    NODE_ENV: z.enum(["development", "production", "test"]).default(process.env.NODE_ENV),
    TEST_ENV: z.string().default(""),
    GOOGLE_MAPS_API_KEY: z.string(),
    LINKEDIN_CLIENT_ID: z.string().default("78vr51gnd5o29w"),
    GOOGLE_CLIENT_ID: z.string().default("1077433431721-f806riujvmd94hgq4veujs1pnekcld30.apps.googleusercontent.com"),
    GOOGLE_API_KEY: z.string().default("5_wnqOuzezPKSTcFQAA_o0i2"),
    OPENREPLAY_PROJECT_KEY: z.string(),
    OPENREPLAY_URL: z.string().default("https://openreplay.geoxanalytics.com/ingest"),
    OPENREPLAY_INSECURE: z
      .enum(["1", "0"])
      .default(process.env.NODE_ENV === "development" ? "1" : "0")
      .transform((v) => v === "1"),
  });

  const envVars = Object.entries(process.env).reduce((acc, [key, value]) => {
    if (key === "NODE_ENV") {
      acc["NODE_ENV"] = value;
    } else if (key.startsWith("REACT_APP_")) {
      acc[key.replace("REACT_APP_", "")] = value;
    }
    return acc;
  }, {});

  const parsedEnv = envSchema.safeParse(envVars);

  if (!parsedEnv.success) {
    throw new Error(
      `Invalid env provided. The following variables are missing or invalid:\n` +
        `${Object.entries(parsedEnv.error.flatten().fieldErrors)
          .map(([key, value]) => `- ${key}: ${value}`)
          .join("\n")}`
    );
  }

  return parsedEnv.data;
}

export const env = createEnv();

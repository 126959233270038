import React, { useEffect, memo, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { S3_BASE_URL } from "services/constants/externalResources";
import { useGsap } from "components/features/GsapProvider";
import { Loader } from "components/presenters/common/Loader";
import { useIsDeviceType } from "services/hooks/useIsDeviceType";

import * as Styled from "./styles";

const house_video = S3_BASE_URL + "assets/house_video.mp4";
const house_video_2 = S3_BASE_URL + "assets/house_video_2.mp4";
const mobile_house_video = S3_BASE_URL + "assets/mobile_house_video.mp4";
const mobile_house_video_2 = S3_BASE_URL + "assets/mobile_house_video_2.mp4";

function SliderSection() {
  const { t } = useTranslation("HomePage");
  const { Gsap, ScrollTo, ScrollTrigger } = useGsap();
  const { isTablet } = useIsDeviceType();

  const firstHouseVideoUrl = isTablet ? mobile_house_video : house_video;
  const secondHouseVideoUrl = isTablet ? mobile_house_video_2 : house_video_2;

  useEffect(() => {
    Gsap.gsap.registerPlugin(ScrollTo.ScrollToPlugin, ScrollTrigger.ScrollTrigger);
  }, [Gsap, ScrollTo.ScrollToPlugin, ScrollTrigger.ScrollTrigger]);

  const initGsapAnimation = useCallback(() => {
    const panelsContainer = document.querySelector("#panels-container");
    const panels = Gsap.gsap.utils.toArray("#panels-container .panel");

    if (!panelsContainer || panels.length === 0) return;

    const offset = panelsContainer.offsetWidth;
    const scrollHeight = isTablet ? offset * 1.5 : offset - offset / 3;

    const animation = Gsap.gsap.to(panels, {
      xPercent: -100 * (panels.length - 1),
      ease: "none",
      scrollTrigger: {
        trigger: "#panels-container",
        pin: true,
        start: "top top",
        scrub: 1,
        snap: {
          snapTo: 1 / (panels.length - 1),
          inertia: false,
          duration: { min: 0.1, max: 0.1 },
        },
        end: "+=" + scrollHeight,
      },
    });

    return () => {
      if (animation) animation.kill();
    };
  }, [Gsap, isTablet]);

  useEffect(() => {
    const killAnimation = initGsapAnimation();
    return () => killAnimation && killAnimation();
  }, [initGsapAnimation]);

  return (
    <section id="panels" data-testid="video-sliders">
      <Styled.Container id="panels-container">
        <Styled.Content className="panel">
          <Styled.Title>{t("Residential Property")}</Styled.Title>
          <Styled.Video autoPlay loop muted id="house_video" playsInline preload="auto" fetchPriority="high">
            <source src={firstHouseVideoUrl} type="video/mp4" />
          </Styled.Video>
        </Styled.Content>

        <Styled.Content className="panel" data-testid="last-slider">
          <Styled.Title>{t("Commercial Property")}</Styled.Title>
          <Styled.Video autoPlay loop muted id="house_video_2" playsInline preload="auto" fetchPriority="high">
            <source src={secondHouseVideoUrl} type="video/mp4" />
          </Styled.Video>
        </Styled.Content>
      </Styled.Container>
    </section>
  );
}

export const SliderWrapper = memo(function SliderWrapper(props) {
  const { isLoaded } = useGsap();
  const { t } = useTranslation("HomePage");

  if (!isLoaded) {
    return (
      <Styled.Container style={{ height: "60vh" }}>
        <Styled.Content>
          <Styled.Title>{t("Residential Property")}</Styled.Title>
          <Loader isLoading position="absolute" />
        </Styled.Content>
      </Styled.Container>
    );
  }

  return <SliderSection {...props} />;
});

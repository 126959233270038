import React from "react";
import styled from "styled-components/macro";

export function VisuallyHidden({ children }) {
  return <VisuallyHiddenStyle>{children}</VisuallyHiddenStyle>;
}

const VisuallyHiddenStyle = styled.span`
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
`;

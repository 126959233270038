import React, { Suspense } from "react";
import EventSource, { sources } from "eventsourcemock";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ErrorBoundary } from "react-error-boundary";
import { QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter } from "react-router-dom";

import ScrollToTop from "components/presenters/common/ScrollToTop";

import { CookieBanner } from "../components/presenters/common/CookieBanner";
import { RouterProvider } from "./providers/router";
import { AuthProvider } from "./providers/auth";
import { GlobalStyle } from "./styles";
import { ErrorFallback } from "../components/presenters/common/ErrorFallback";
import { queryClient } from "./providers/react-query";
import { ToastsProvider } from "./providers/ToastsProvider";
import { Loader } from "../components/presenters/common/Loader";
import { useTidioChat } from "./hooks/useTidioChat";
import { useGoogleAnalytics } from "./hooks/useGoogleAnalytics";

if (window["Cypress"]) {
  Object.defineProperty(window, "EventSource", { value: EventSource });
  Object.defineProperty(window, "mockEventSources", { value: sources });
}

export function AppProvider({ children }) {
  useTidioChat();
  useGoogleAnalytics();

  return (
    <Suspense fallback={<div />}>
      <GlobalStyle />
      <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => window.location.reload(true)}>
        <QueryClientProvider client={queryClient}>
          <div className="App">
            <BrowserRouter>
              <ScrollToTop>
                <CookieBanner />
                <RouterProvider>
                  <AuthProvider>{children}</AuthProvider>
                </RouterProvider>
              </ScrollToTop>
            </BrowserRouter>

            <Loader />
          </div>
          <ReactQueryDevtools />
          <ToastsProvider />
        </QueryClientProvider>
      </ErrorBoundary>
    </Suspense>
  );
}

import React from "react";

import { ReactComponent as BurgerIcon } from "assets/icons/menu-burger.svg";
import { useBooleanState } from "services/hooks/useBooleanState";
import { useAuth } from "app/providers/auth";

import { Logo } from "../Logo";
import { MobileMenu } from "../MobileMenu";
import { LangSwitcher } from "../../../../common/LangSwitcher";
import { Notification } from "../../../../common/Notification";
import * as Styled from "../../style";

export function MobileHeader() {
  const [isMenuOpen, setNotMenuOpen, setIsMenuOpen] = useBooleanState();
  const { isSignedIn } = useAuth();

  return (
    <>
      <Styled.HeaderSpace />
      <Styled.HeaderWrapper>
        <Styled.Header>
          <Logo />

          <Styled.HeaderBox>
            <Styled.SwitcherWrapper>
              <LangSwitcher />
            </Styled.SwitcherWrapper>

            {isSignedIn && <Notification />}

            <Styled.BurgerButton onClick={setIsMenuOpen} aria-label="menu">
              <BurgerIcon />
            </Styled.BurgerButton>
          </Styled.HeaderBox>
        </Styled.Header>
      </Styled.HeaderWrapper>

      <MobileMenu open={isMenuOpen} close={setNotMenuOpen} />
    </>
  );
}

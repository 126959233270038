import { useLocation } from "react-router-dom";
import { useEffect } from "react";

export function useLCPPreload() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === "/" || pathname === "/home") {
      const link = document.createElement("link");
      link.rel = "preload";
      link.as = "image";
      link.href = "/assets/images/video-poster.webp";
      link.type = "image/webp";
      link.fetchpriority = "high";

      document.head.appendChild(link);

      return () => {
        document.head.removeChild(link);
      };
    }
  }, [pathname]);
}

import basicConfig from "./basicConfig";

const _ = undefined;

const projectAPI = {
  getProjectDetails(project_id, response_type) {
    const params = {
      project_id,
      response_type,
    };
    return basicConfig
      .createRequest("/project/details", "GET", { "content-type": "application/json" }, _, _, params)
      .then((res) => res.data);
  },

  getAddresses(params) {
    return basicConfig
      .createRequest("/project/addresses", "GET", { "content-type": "application/json" }, _, _, params)
      .then((res) => res.data);
  },

  deleteAddress(project_id, address_id) {
    const params = {
      project_id,
      address_id,
    };

    return basicConfig
      .createRequest("/project/address", "DELETE", { "content-type": "application/json" }, _, _, params)
      .then((res) => res.data);
  },

  uploadFiles(project_id, data) {
    const params = {
      project_id,
    };

    return basicConfig.createRequest("/v1/project/upload", "POST", {}, data, true, params).then((res) => res.data.data);
  },

  updateProject(data) {
    return basicConfig
      .createRequest("/projects", "POST", { "content-type": "application/json" }, data)
      .then((res) => res.data.data);
  },

  getAllProjects() {
    return basicConfig
      .createRequest("/projects", "GET", { "content-type": "application/json" }, _, _, {
        q: { page_size: 100 },
      })
      .then((res) => res.data.data);
  },

  searchProjectByAddress(address) {
    return basicConfig
      .createRequest("/v1/projects/search-via-address", "GET", { "content-type": "application/json" }, _, _, {
        search_text: address,
      })
      .then((res) => res.data);
  },

  updateAddress(project_id, address_id, data) {
    const params = {
      project_id,
      address_id,
    };

    return basicConfig.createRequest(
      "/project/address",
      "PATCH",
      { "content-type": "application/json" },
      data,
      _,
      params
    );
  },

  updateParcelAddressRecord(address_id, data) {
    return basicConfig.createRequest(
      `/v1/parcels/${address_id}`,
      "PATCH",
      { "content-type": "application/json" },
      data
    );
  },

  addAddress(payload) {
    const { project_id, ...data } = payload;

    const params = {
      project_id,
    };

    return basicConfig.createRequest(
      "/project/address",
      "POST",
      { "content-type": "application/json" },
      data,
      _,
      params
    );
  },

  getAddressProjectDetail(project_id, data) {
    const params = {
      project_id,
    };

    return basicConfig
      .createRequest("/address/filter", "POST", { "content-type": "application/json" }, data, _, params)
      .then((res) => res.data);
  },

  getFilteredAddresses(project_id, data) {
    const params = {
      project_id,
    };

    return basicConfig
      .createRequest("/project/addresses-filtered", "POST", { "content-type": "application/json" }, data, _, params)
      .then((res) => res.data);
  },

  getFilteredAddressesDetail(project_id, page_num, page_size, sort_key = "address", sort_direction = "down", data) {
    const params = {
      project_id,
      page_num,
      page_size,
      sort_key,
      sort_direction,
    };

    return basicConfig
      .createRequest(
        "/project/addresses-filtered-detail",
        "POST",
        { "content-type": "application/json" },
        data,
        _,
        params
      )
      .then((res) => res.data);
  },

  getAddressesDetail(project_id, page_num, page_size, sort_key = "address", sort_direction = "down") {
    const params = {
      project_id,
      page_num,
      page_size,
      sort_key,
      sort_direction,
    };

    return basicConfig
      .createRequest("/project/addresses-filtered-detail", "GET", { "content-type": "application/json" }, _, _, params)
      .then((res) => res.data);
  },

  getBuildings(project_id, address_id) {
    const params = {
      project_id,
      address_id,
    };

    return basicConfig
      .createRequest("/project/address/buildings", "GET", { "content-type": "application/json" }, _, _, params)
      .then((res) => res.data);
  },

  getParcelBuildings(parcel_id, data) {
    return basicConfig
      .createRequest(`/v1/parcel/${parcel_id}/buildings`, "POST", { "content-type": "application/json" }, data, _, _)
      .then((res) => res.data);
  },

  adminUpdateUserProject(id, payload) {
    return basicConfig
      .createRequest(`/v1/admin/projects/${id}`, "PATCH", { "content-type": "application/json" }, payload)
      .then((res) => res.data);
  },

  adminFilterProjects(payload) {
    return basicConfig
      .createRequest("/v1/admin/projects/filter", "POST", { "content-type": "application/json" }, payload)
      .then((res) => res.data);
  },

  saveProjectsOrder(data) {
    return basicConfig
      .createRequest("/v1/customer/projects-order", "POST", { "content-type": "application/json" }, data)
      .then((res) => res.data);
  },

  deleteProjectById(project_id) {
    return basicConfig.createRequest(
      `/v1/projects/${project_id}`,
      "DELETE",
      { "content-type": "application/json" },
      _,
      _
    );
  },

  getProjectStats(project_id) {
    return basicConfig.createRequest(`/v1/project/${project_id}/stats`, "GET", {
      "content-type": "application/json",
    });
  },

  getParcelGeometryAndBuildingPoints(project_id, parcel_id) {
    const params = {
      project_id,
      parcel_id,
    };

    return basicConfig.createRequest(
      "/v1/parcels/get-boundary-and-points",
      "GET",
      {
        "content-type": "application/json",
      },
      _,
      _,
      params
    );
  },

  checkIsProjectNameUnique(projectName) {
    return basicConfig.createRequest(`/v1/projects/check-name?project_name=${projectName}`, "GET", {
      "content-type": "application/json",
    });
  },

  exportFullTableOfDataExcel(projectId) {
    return basicConfig
      .createRequest(`/v1/projects/${projectId}/export-full-table/excel`, "GET")
      .then((res) => res.data);
  },

  fetchPopup() {
    return basicConfig.createRequest("/v1/get-popup", "GET", _, _, _, _, true).then((res) => res.data);
  },

  markPopupFinished(id) {
    return basicConfig.createRequest(`/v1/mark-popup-finished/${id}`, "POST", _, _, _, _, true).then((res) => res.data);
  },

  getParcelIds(project_id, page_num, page_size, sort_key = "address", sort_direction = "down", data) {
    const params = {
      page_num,
      page_size,
      sort_key,
      sort_direction,
    };

    return basicConfig
      .createRequest(
        `/v1/project/${project_id}/get-parcel-ids`,
        "POST",
        { "content-type": "application/json" },
        data,
        _,
        params
      )
      .then((res) => res.data);
  },

  downloadFileOfExportTask(projectId) {
    return basicConfig
      .createRequest(`/v1/file-tasks/download-exported-file/${projectId}`, "POST", _, _, _, _, true)
      .then((res) => res.data);
  },

  getAdvancedProjects(body) {
    return basicConfig.createRequest(`v1/admin/projects/filter`, "POST", _, _, _, body).then((res) => res.data);
  },
  uploadAdvancedProjectResults(data, project_id) {
    return basicConfig
      .createRequest(`v1/project/preview`, "POST", { "Content-Type": "multipart/form-data" }, data, true, {
        project_id,
      })
      .then((res) => res.data);
  },
  uploadAndSaveAdvancedProjectResults(data, project_id) {
    return basicConfig
      .createRequest(`v1/project/upload`, "POST", { "Content-Type": "multipart/form-data" }, data, true, {
        project_id,
      })
      .then((res) => res.data);
  },
};

export default projectAPI;

import React from "react";
import ReactDOM from "react-dom";

import * as Styled from "./styles";

function WelcomeOverlay() {
  return (
    <Styled.Overlay>
      <Styled.Logo />
    </Styled.Overlay>
  );
}

export function WelcomeAnimation() {
  const root = document.querySelector("#upper-content-root");

  ReactDOM.render(<WelcomeOverlay />, root);

  setTimeout(() => {
    ReactDOM.unmountComponentAtNode(root);
  }, 4000);
}

import basicConfig from "./basicConfig";

const _ = undefined;

const userAPI = {
  updateUserInfo(payload) {
    return basicConfig
      .createRequest("/customer", "PATCH", { "content-type": "application/json" }, payload)
      .then((res) => res.data);
  },

  updateUserPicture(payload) {
    return basicConfig
      .createRequest("/customer/profile-picture", "POST", { "content-type": "application/json" }, payload, true)
      .then((res) => res.data);
  },

  getUser() {
    return basicConfig
      .createRequest("/customer", "GET")
      .then((res) => res.data.data)
      .catch((error) => Promise.reject(error.response));
  },

  getUserBasicInfo() {
    return basicConfig.createRequest("/v1/customer/profile/basic", "GET").then((res) => res.data);
  },

  getUserPicture() {
    return basicConfig.createRequest("/customer/profile-picture", "GET").then((res) => res.data);
  },

  getNotifications() {
    return basicConfig.createRequest("/notifications", "POST").then((res) => {
      return res.data;
    });
  },

  getCurrentPlan() {
    return basicConfig.createRequest("/customer/my-plan", "GET").then((res) => {
      return res.data;
    });
  },

  adminFilterUsers(payload) {
    return basicConfig
      .createRequest("/v1/admin/customers/filter", "POST", { "content-type": "application/json" }, payload)
      .then((res) => res.data);
  },

  adminCreateUser(data) {
    return basicConfig
      .createRequest("/v1/admin/customers", "POST", { "content-type": "application/json" }, data)
      .then((res) => res.data);
  },

  adminUpdateUser(id, data) {
    return basicConfig
      .createRequest(`/v1/admin/customers/${id}`, "PATCH", { "content-type": "application/json" }, data)
      .then((res) => res.data);
  },

  adminFilterContactUs(payload) {
    return basicConfig
      .createRequest("/v1/admin/contact-us/filter", "POST", { "content-type": "application/json" }, payload)
      .then((res) => res.data);
  },

  adminContactUs() {
    return basicConfig
      .createRequest("/v1/admin/contact-us", "GET", { "content-type": "application/json" })
      .then((res) => res.data);
  },

  adminFilterFeedback(payload) {
    return basicConfig
      .createRequest("/v1/admin/feedback/filter", "POST", { "content-type": "application/json" }, payload)
      .then((res) => res.data);
  },

  adminDeleteUser(userId) {
    return basicConfig.createRequest(`/delete/customer/${userId}`, "DELETE").then((res) => res);
  },

  changePassword(current_password, new_password) {
    const payload = {
      current_password,
      new_password,
    };

    return basicConfig
      .createRequest("/customer/password", "POST", { "content-type": "application/json" }, payload)
      .then((res) => res.data);
  },

  resetPassword(email) {
    return basicConfig
      .createRequest("/v1/auth/send-reset-password-email", "POST", { "content-type": "application/json" }, { email })
      .then((res) => res.data);
  },

  validateResetPasswordToken(token) {
    return basicConfig
      .createRequest(
        "/v1/auth/validate-reset-password-token",
        "POST",
        { "content-type": "application/json" },
        { token }
      )
      .then((res) => res.data);
  },

  resetPasswordWithToken(new_password, token) {
    return basicConfig
      .createRequest(
        "/v1/auth/reset-password-with-token",
        "POST",
        { "content-type": "application/json" },
        { new_password, token }
      )
      .then((res) => res.data);
  },

  blockUser(id) {
    return basicConfig
      .createRequest(`/v1/admin/customers/${id}`, "PATCH", { "content-type": "application/json" }, { is_blocked: true })
      .then((res) => res.data);
  },

  unblockUser(id) {
    return basicConfig
      .createRequest(
        `/v1/admin/customers/${id}`,
        "PATCH",
        { "content-type": "application/json" },
        { is_blocked: false }
      )
      .then((res) => res.data);
  },

  resetWrongPasswordBlock(id) {
    return basicConfig
      .createRequest(
        `/v1/admin/customers/${id}`,
        "PATCH",
        { "content-type": "application/json" },
        { reset_wrong_password_block: true }
      )
      .then((res) => res.data);
  },

  sendFeedback(payload) {
    return basicConfig
      .createRequest("/v1/feedback", "POST", { "content-type": "application/json" }, payload)
      .then((res) => res.data);
  },

  sendEmailVerification() {
    return basicConfig
      .createRequest("/v1/auth/start-email-verification-process", "POST", {
        "content-type": "application/json",
      })
      .then((res) => res.data);
  },

  processEmailVerificationToken(token, password) {
    return basicConfig
      .createRequest(
        "/v1/auth/process-email-verification-token",
        "POST",
        { "content-type": "application/json" },
        { token, password }
      )
      .then((res) => res.data);
  },

  exportCustomers() {
    return basicConfig
      .createRequest(
        "/v1/admin/customers/export",
        "GET",
        {
          "content-type": "text/csv",
        },
        _,
        _,
        _,
        true
      )
      .then((res) => res.data);
  },

  unsubscribeNewsLetter({ email, reason = "" }) {
    return basicConfig
      .createRequest("/v1/customer/unsubscribe", "POST", { "content-type": "application/json" }, { email, reason })
      .then((res) => res.data);
  },

  resubscribeNewsLetter({ email }) {
    return basicConfig
      .createRequest("/v1/customer/resubscribe", "POST", { "content-type": "application/json" }, { email })
      .then((res) => res.data);
  },
};

export default userAPI;

import React from "react";
import { Redirect, Route } from "react-router-dom";

import { useAuth } from "../../../auth";
import { ROUTE_PATHS } from "../../consts";
import { CountryProvider, FiltersProvider, MapProvider } from "../../../business";

export function PrivateRoute({ children, ...rest }) {
  const auth = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) => {
        return auth.isSignedIn === true ? (
          <CountryProvider>
            <FiltersProvider>
              <MapProvider>{children}</MapProvider>
            </FiltersProvider>
          </CountryProvider>
        ) : (
          <Redirect to={{ pathname: ROUTE_PATHS.SIGN_IN, state: { from: location } }} />
        );
      }}
    />
  );
}

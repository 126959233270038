import { useEffect } from "react";

export function useLoadBootstrap({ isAdmin = false }) {
  useEffect(() => {
    if (!isAdmin) return;

    const jqueryJS = document.createElement("script");
    jqueryJS.src = "https://code.jquery.com/jquery-3.5.1.slim.min.js";
    jqueryJS.async = true;

    const bootstrapCSS = document.createElement("link");
    bootstrapCSS.rel = "stylesheet";
    bootstrapCSS.href = "https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/css/bootstrap.min.css";

    const bootstrapJS = document.createElement("script");
    bootstrapJS.src = "https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/js/bootstrap.bundle.min.js";
    bootstrapJS.defer = true;

    const fontAwesome = document.createElement("script");
    fontAwesome.src = "https://kit.fontawesome.com/a7b2435aab.js";
    fontAwesome.crossOrigin = "anonymous";

    document.head.appendChild(bootstrapCSS);
    document.head.appendChild(fontAwesome);

    jqueryJS.onload = () => {
      document.body.appendChild(bootstrapJS);
    };

    document.body.appendChild(jqueryJS);

    return () => {
      if (bootstrapCSS.parentNode) document.head.removeChild(bootstrapCSS);
      if (jqueryJS.parentNode) document.body.removeChild(jqueryJS);
      if (bootstrapJS.parentNode) document.body.removeChild(bootstrapJS);
      if (fontAwesome.parentNode) document.head.removeChild(fontAwesome);
    };
  }, [isAdmin]);
}

import React from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as CloseIcon } from "assets/icons/close_icon_dark.svg";
import { Mixpanel } from "services/tracking/Mixpanel";
import { Button } from "components/presenters/common/Button";
import { ROUTE_PATHS } from "app/providers/router";
import { openTidioChat } from "services/utils/tidioChat";
import { useAuth } from "app/providers/auth";

import * as Styled from "./style";

export function MobileMenu({ open, close }) {
  const { t } = useTranslation();
  const auth = useAuth();

  return (
    <Styled.MenuPopup open={open} modal closeOnDocumentClick closeOnEscape onClose={close} lockScroll>
      <Button onClick={close} variant="ghost" style={{ maxWidth: "44px", alignSelf: "end" }} data-testid="close-menu">
        <CloseIcon />
      </Button>

      <Styled.NavigationList>
        {!auth.isSignedIn ? (
          <>
            <li>
              <Styled.NavigationLink to={ROUTE_PATHS.HOME}>{t("Home")}</Styled.NavigationLink>
            </li>
            <li>
              <Styled.NavigationLink to={ROUTE_PATHS.PLATFORM}>{t("Platform")}</Styled.NavigationLink>
            </li>
            <li>
              <Styled.NavigationLink to={ROUTE_PATHS.ABOUT_US}>{t("About Us")}</Styled.NavigationLink>
            </li>
            <li>
              <Styled.NavigationLink to={ROUTE_PATHS.SIGN_IN} onClick={close}>
                {t("Sign In")}
              </Styled.NavigationLink>
            </li>
            <li>
              <Styled.NavigationLink to={ROUTE_PATHS.SIGN_UP} onClick={close}>
                {t("Get started")}
              </Styled.NavigationLink>
            </li>
          </>
        ) : (
          <>
            <li>
              <Styled.NavigationLink to={ROUTE_PATHS.SINGLE_SEARCH}>{t("Search")}</Styled.NavigationLink>
            </li>
            <li>
              <Styled.NavigationLink to={ROUTE_PATHS.PROJECTS}>{t("Projects")}</Styled.NavigationLink>
            </li>{" "}
            <li>
              <Styled.NavigationLink to={ROUTE_PATHS.API_TEST}>{t("API")}</Styled.NavigationLink>
            </li>
            <li>
              <Styled.NavigationLink
                as="div"
                onClick={() => {
                  Mixpanel.track("Click to support from Header on tablet or mobile");
                  close();
                  openTidioChat();
                }}
              >
                {t("Support")}
              </Styled.NavigationLink>
            </li>
            <li>
              <Styled.NavigationLink to="/settings/profile">{t("My Account")}</Styled.NavigationLink>
            </li>
            <li>
              <Button
                onClick={() => auth.signOutAction()}
                variant="contained"
                style={{ maxWidth: "150px", margin: "0 auto", marginTop: "48px" }}
              >
                {t("Sign Out")}
              </Button>
            </li>
          </>
        )}
      </Styled.NavigationList>
    </Styled.MenuPopup>
  );
}

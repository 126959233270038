import styled from "styled-components";

import { COLORS, Container as SContainer, FONTS, QUERIES, WEIGHT } from "configs/styles/common";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  height: 100vh;
  padding-inline: 0;
  padding-block: 35px 30px;

  @media ${QUERIES.upToTablet} {
    height: 660px;
  }
`;

export const Title = styled.h3`
  margin-left: auto;
  margin-right: auto;
  color: ${COLORS.DARK.Main};
  font-family: ${FONTS.Title};
  font-weight: ${WEIGHT.bold};
  font-size: 32px;
  line-height: 35px;
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  text-align: center;

  @media ${QUERIES.upToTablet} {
    top: 2px;
  }
`;

export const Content = styled(SContainer)`
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  position: relative;
  max-width: 100vw;
`;

export const Video = styled.video`
  width: 100%;
  height: auto;
  margin: 0 auto;
  max-width: 1270px;

  @media ${QUERIES.upToTablet} {
    padding-top: 30px;
  }
`;

export const MobileContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  height: 100vh;
  padding: 30px;
  padding-top: 130px;
  box-sizing: border-box;

  @media ${QUERIES.upToMobile} {
    flex-wrap: wrap;
    gap: 40px;
    height: 100%;
    padding: 0;
    padding-top: 40px;
  }
`;

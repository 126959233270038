import React from "react";
import { Redirect, Route } from "react-router-dom";

import { useAuth } from "../../../auth";
import { ROUTE_PATHS } from "../../consts";

export function PublicRoute({ children, ...rest }) {
  const auth = useAuth();

  return (
    <Route
      {...rest}
      render={() => {
        return auth.isSignedIn === false ? children : <Redirect to={ROUTE_PATHS.SINGLE_SEARCH} />;
      }}
    />
  );
}

import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { LinkedInCallback } from "react-linkedin-login-oauth2";

import { HomePage } from "pages/Public/HomePage";
import { SignInPage } from "pages/Public/SignInPage";
import { AdminLogin } from "pages/admin/AdminLogin";
import { EmailVerification } from "pages/Public/EmailVerification";
import { SignUpPage } from "pages/Public/SignUpPage";
import { CookieNotice, Privacy, Terms } from "pages/Public/Policy";
import { ContactUsPage } from "pages/Public/ContactUsPage";
import { ForgotPasswordPage } from "pages/Public/ForgotPassword";
import { ResetPasswordPage } from "pages/Public/ResetPasswordPage";
import { UnsubscribePage } from "pages/Public/UnsubscribePage";
import { AboutUs } from "pages/Public/AboutUs";
import { PlatformPage } from "pages/Public/PlatformPage";
import { News1 } from "pages/Public/_news/News1";
import { News2 } from "pages/Public/_news/News2";
import { News3 } from "pages/Public/_news/News3";
import { AdminPage } from "pages/admin/AdminPage";
import { ManagePlans } from "pages/admin/ManagePlans";
import { ManageUsers } from "pages/admin/ManageUsers";
import { ManageCoupons } from "pages/admin/ManageCoupons";
import { ManageProjects } from "pages/admin/ManageProjects";
import { ManageContactUs } from "pages/admin/ManageContactUs";
import { ManageFeedback } from "pages/admin/ManageFeedback";
import { LoginActivity } from "pages/admin/LoginActivity";
import { ManageOrders } from "pages/admin/ManageOrders";
import { ManageBills } from "pages/admin/ManageBills";
import { AdvancedReports } from "pages/admin/AdvancedReports";
import { SingleSearchPage } from "pages/Private/SingleSearch";
import { ProjectsList } from "pages/Private/ProjectsList";
import { SettingsPage } from "pages/Private/SettingsPage";
import { ChangePasswordPage } from "pages/Private/ChangePasswordPage";
import { APITestPage } from "pages/Private/APITestPage";
import { CompletedProjectPage } from "pages/Private/CompletedProjectPage";
import { AddressViewPage } from "pages/Private/AddressView";
import { PdfExport } from "pages/Private/PdfExport";
import { NotFoundPage } from "pages/Private/NotFoundPage";
import { CreatedProjectPage } from "pages/Private/CreatedProjectPage";
import { useOpenreplayTracker } from "services/tracking/openreplayTracker/useOpenreplayTracker";

import { AdminRoute } from "./route-guards/AdminRoute";
import { PrivateRoute } from "./route-guards/PrivateRoute";
import { PublicRoute } from "./route-guards/PublicRoute";
import { ROUTE_PATHS } from "./consts";

export function AppRouter() {
  useOpenreplayTracker();

  return (
    <Suspense fallback={<div />}>
      <Switch>
        <PublicRoute path="/" exact>
          <HomePage />
        </PublicRoute>
        <PublicRoute path={ROUTE_PATHS.HOME}>
          <HomePage />
        </PublicRoute>

        <PublicRoute path={ROUTE_PATHS.ADMIN_LOGIN} exact>
          <AdminLogin />
        </PublicRoute>
        <PublicRoute path={ROUTE_PATHS.SIGN_IN}>
          <SignInPage />
        </PublicRoute>
        <PublicRoute path={ROUTE_PATHS.VERIFY_EMAIL}>
          <EmailVerification />
        </PublicRoute>
        <PublicRoute path={ROUTE_PATHS.SIGN_UP}>
          <SignUpPage />
        </PublicRoute>
        <PublicRoute path={ROUTE_PATHS.COOKIE_NOTICE}>
          <CookieNotice />
        </PublicRoute>
        <PublicRoute path={ROUTE_PATHS.PRIVACY}>
          <Privacy />
        </PublicRoute>
        <PublicRoute path={ROUTE_PATHS.TERMS}>
          <Terms />
        </PublicRoute>
        <PublicRoute path={ROUTE_PATHS.CONTACT_US}>
          <ContactUsPage />
        </PublicRoute>
        <PublicRoute path={ROUTE_PATHS.FORGOT_PASSWORD}>
          <ForgotPasswordPage />
        </PublicRoute>
        <PublicRoute path={ROUTE_PATHS.RESET_PASSWORD}>
          <ResetPasswordPage />
        </PublicRoute>
        <PublicRoute path={ROUTE_PATHS.UNSUBSCRIBE}>
          <UnsubscribePage />
        </PublicRoute>
        <PublicRoute path={ROUTE_PATHS.ABOUT_US}>
          <AboutUs />
        </PublicRoute>
        <PublicRoute path={ROUTE_PATHS.PLATFORM}>
          <PlatformPage />
        </PublicRoute>
        <PublicRoute path={ROUTE_PATHS.NEWS_1}>
          <News1 />
        </PublicRoute>
        <PublicRoute path={ROUTE_PATHS.NEWS_2}>
          <News2 />
        </PublicRoute>
        <PublicRoute path={ROUTE_PATHS.NEWS_3}>
          <News3 />
        </PublicRoute>
        <PublicRoute exact path={ROUTE_PATHS.LINKEDIN}>
          <LinkedInCallback />
        </PublicRoute>

        <AdminRoute path={ROUTE_PATHS.ADMIN} exact>
          <AdminPage />
        </AdminRoute>
        <AdminRoute path={ROUTE_PATHS.ADMIN_MANAGE_PLANS} exact>
          <ManagePlans />
        </AdminRoute>
        <AdminRoute path={ROUTE_PATHS.ADMIN_MANAGE_USERS} exact>
          <ManageUsers />
        </AdminRoute>
        <AdminRoute path={ROUTE_PATHS.ADMIN_MANAGE_COUPONS} exact>
          <ManageCoupons />
        </AdminRoute>
        <AdminRoute path={ROUTE_PATHS.ADMIN_MANAGE_PROJECTS} exact>
          <ManageProjects />
        </AdminRoute>
        <AdminRoute path={ROUTE_PATHS.ADMIN_CONTACT_US} exact>
          <ManageContactUs />
        </AdminRoute>
        <AdminRoute path={ROUTE_PATHS.ADMIN_FEEDBACK} exact>
          <ManageFeedback />
        </AdminRoute>
        <AdminRoute path={ROUTE_PATHS.ADMIN_LOGIN_ACTIVITY} exact>
          <LoginActivity />
        </AdminRoute>
        <AdminRoute path={ROUTE_PATHS.ADMIN_MANAGE_ORDERS} exact>
          <ManageOrders />
        </AdminRoute>
        <AdminRoute path={ROUTE_PATHS.ADMIN_BILLS} exact>
          <ManageBills />
        </AdminRoute>
        <AdminRoute path={ROUTE_PATHS.ADMIN_REPORTS} exact>
          <AdvancedReports />
        </AdminRoute>

        <PrivateRoute path={ROUTE_PATHS.SINGLE_SEARCH}>
          <SingleSearchPage />
        </PrivateRoute>
        <PrivateRoute path={ROUTE_PATHS.PROJECTS}>
          <ProjectsList />
        </PrivateRoute>
        <PrivateRoute path={ROUTE_PATHS.SETTINGS}>
          <SettingsPage />
        </PrivateRoute>
        <PrivateRoute path={ROUTE_PATHS.CHANGE_PASSWORD}>
          <ChangePasswordPage />
        </PrivateRoute>
        <PrivateRoute path={ROUTE_PATHS.API_TEST}>
          <APITestPage />
        </PrivateRoute>

        <PrivateRoute path={ROUTE_PATHS.CREATED_PROJECT({ projectId: ":projectId", projectType: ":projectType" })}>
          <CreatedProjectPage />
        </PrivateRoute>

        <PrivateRoute
          path={ROUTE_PATHS.COMPLETED_PROJECT({
            projectId: ":projectId",
            projectType: ":projectType",
            tab: ":tab",
          })}
        >
          <CompletedProjectPage />
        </PrivateRoute>

        <PrivateRoute
          path={ROUTE_PATHS.ADDRESS_VIEW_PAGE({
            projectId: ":projectId",
            addressId: ":addressId",
            buildingNum: ":buildingNum",
          })}
        >
          <AddressViewPage />
        </PrivateRoute>

        <PrivateRoute
          path={ROUTE_PATHS.PDF_EXPORT({
            projectType: ":projectType",
            projectId: ":projectId",
            addressId: ":addressId",
          })}
        >
          <PdfExport />
        </PrivateRoute>

        <Route path="*">
          <NotFoundPage />
        </Route>
      </Switch>
    </Suspense>
  );
}

import { useEffect } from "react";

import { useAuth } from "app/providers/auth";

import { startTracker, stopTracker, tracker } from "./index";

export function useOpenreplayTracker() {
  const { isSignedIn, user, isAdmin } = useAuth();

  useEffect(() => {
    async function startTracking() {
      try {
        if (isSignedIn && !tracker && user && !isAdmin) {
          await startTracker({
            userID: user?.customer_id,
            metadata: {
              country: user?.country,
              plan: user?.curr_data_plan,
              credits: user?.curr_data_plan_credits,
            },
          });
        } else if (!isSignedIn && tracker) {
          stopTracker();
        }
      } catch (error) {
        console.error(error);
      }
    }

    startTracking();

    return () => {
      if (tracker) {
        stopTracker();
      }
    };
  }, [isSignedIn, user, isAdmin]);
}

import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "/api",
});

let isRefreshing = false;
let refreshSubscribers = [];

const subscribeTokenRefresh = (callback) => {
  refreshSubscribers.push(callback);
};

const onRefreshed = () => {
  refreshSubscribers.forEach((callback) => callback());
  refreshSubscribers = [];
};

export const refreshToken = () => {
  return axiosInstance.post("/auth/refresh-token", {});
};

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (!isRefreshing) {
        isRefreshing = true;
        try {
          await refreshToken();
          isRefreshing = false;
          onRefreshed();
        } catch (err) {
          isRefreshing = false;
          return Promise.reject(err);
        }
      }

      return new Promise((resolve) => {
        subscribeTokenRefresh(() => {
          resolve(axiosInstance(originalRequest));
        });
      });
    }

    return Promise.reject(error);
  }
);

const basicConfig = {
  createRequest(endpoint, method, headers = {}, data, isFormData = false, params, isBlob = false) {
    const options = {
      method,
      url: endpoint,
      headers: isFormData ? headers : { ...headers, "Content-Type": "application/json" },
      data: isFormData ? data : JSON.stringify(data),
      params,
      responseType: isBlob ? "blob" : undefined,
    };

    return axiosInstance(options);
  },
};

export default basicConfig;

import { useEffect } from "react";
import ReactGA from "react-ga";

export function useGoogleAnalytics() {
  useEffect(() => {
    if (process.env.REACT_APP_GA_ENABLED === "true") {
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, []);
}

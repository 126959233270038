import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import { Select } from "../../../inputs/Select";
import { langStyle } from "./LangSwitcher.style";

const LANG_OPTIONS = [
  {
    label: "English",
    value: "en-US",
  },
  { label: "日本語", value: "ja" },
];

export const LangSwitcher = memo(function LangSwitcher({ ...delegated }) {
  const { i18n } = useTranslation();

  function onLangChange(value) {
    i18n.changeLanguage(value.value);
    try {
      localStorage.setItem("lang", value.value);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <Select
        aria-label="language switcher"
        ariaLiveMessages={{
          onFocus: () => "You are currently on the language switcher",
          onChange: ({ label }) => `Language changed to ${label}`,
        }}
        aria-live="polite"
        options={LANG_OPTIONS}
        onChange={onLangChange}
        placeholder=""
        controlShouldRenderValue={false}
        specificStyle={langStyle}
        isSearchable={false}
        {...delegated}
      />
    </>
  );
});

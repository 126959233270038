import { useEffect } from "react";

import { useIsDeviceType } from "../../services/hooks/useIsDeviceType";

export function useTidioChat() {
  const { isTablet } = useIsDeviceType();

  useEffect(() => {
    function onTidioChatApiReady() {
      window.tidioChatApi.hide();
      window.tidioChatApi.on("close", function () {
        window.tidioChatApi.hide();
      });
    }

    if (isTablet) {
      if (window.tidioChatApi) {
        window.tidioChatApi.on("ready", onTidioChatApiReady);
      } else {
        document.addEventListener("tidioChat-ready", onTidioChatApiReady);
      }
    }
  }, [isTablet]);
}

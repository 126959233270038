import React from "react";
import { BellIcon } from "@heroicons/react/24/outline";
import { BellIcon as BellSolidIcon } from "@heroicons/react/24/solid";

import API from "api/apiClient";
import { COLORS } from "configs/styles/common";

import * as Styled from "../ui/style";
import { VisuallyHidden } from "../../VisuallyHidden";

export function NotificationButton({
  onclick,
  visible,
  setNotifications,
  isNewNotification,
  setIsNewNotification,
  setIsLoading,
}) {
  const handleButtonClick = () => {
    onclick();
    setIsLoading(true);
    setIsNewNotification(false);

    API.getNotifications().then((data) => {
      setNotifications(data.data.customer_notifications);
      setIsLoading(false);
    });
  };

  return (
    <Styled.BellWrapper onClick={handleButtonClick} isNewNotification={isNewNotification}>
      <VisuallyHidden>{"New notifications"}</VisuallyHidden>
      {!visible && !isNewNotification && <BellIcon />}

      {visible && !isNewNotification && <BellSolidIcon />}

      {isNewNotification && <BellSolidIcon style={{ color: COLORS.ACCENT }} />}
    </Styled.BellWrapper>
  );
}

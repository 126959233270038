import React from "react";

import { AppProvider } from "./app-provider";
import { AppRouter } from "./providers/router/AppRouter";

export function App() {
  return (
    <AppProvider>
      <AppRouter />
    </AppProvider>
  );
}

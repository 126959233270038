import styled from "styled-components";

import { AnimatedLogo } from "assets/images/logo";

export const Overlay = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: white;
  color: red;
  z-index: 99999;
  animation: fade-out 4s;

  @supports (height: 1dvh) {
    height: 100dvh;
    width: 100dvw;
  }

  @keyframes fade-out {
    0% {
      opacity: 1;
    }

    75% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
`;

export const Logo = styled.div`
  position: absolute;
  width: 300px;
  height: 150px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: URL(${AnimatedLogo});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  color: red;
  z-index: 999999;
`;

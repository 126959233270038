import Cookies from "js-cookie";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ROUTE_PATHS } from "app/providers/router";

import { VisuallyHidden } from "../VisuallyHidden";
import * as Styled from "./CookieBanner.style";

export function CookieBanner() {
  const { t } = useTranslation();

  const [isCookieAccepted, setIsCookieAccepted] = useState(Cookies.get("AcceptCookie") === "true");
  const [isCookieRefused, setIsCookieRefused] = useState(Cookies.get("RefuseCookie") === "true");

  function onAcceptCookieClick() {
    setIsCookieAccepted(true);
    Cookies.set("AcceptCookie", "true", { expires: 3 });
  }

  function onRefuseClick() {
    setIsCookieRefused(true);
    Cookies.set("RefuseCookie", "true", { expires: 3 });
  }

  if (isCookieAccepted || isCookieRefused) return null;

  return (
    <Styled.Wrapper>
      <Styled.InnerBox>
        <Styled.CookieText>
          {t(
            "GeoX website uses cookies to customize and improve the content shown to you, making sure you get the best experience on our website."
          )}
        </Styled.CookieText>

        <Styled.CookieLink to={ROUTE_PATHS.COOKIE_NOTICE}>{t("Learn more")}</Styled.CookieLink>
      </Styled.InnerBox>

      <Styled.RefuseButton onClick={onRefuseClick} data-testid="refuse">
        <VisuallyHidden>{t("Close")}</VisuallyHidden>
      </Styled.RefuseButton>

      <Styled.CookieButton onClick={onAcceptCookieClick} data-testid="accept-cookie">
        {t("Accept")}
      </Styled.CookieButton>
    </Styled.Wrapper>
  );
}

import React from "react";

import { useIsDeviceType } from "services/hooks/useIsDeviceType";
import { useAuth } from "app/providers/auth";

import { MobileHeader, PrivateLaptopHeader, PublicLaptopHeader } from "./components";

export function Header() {
  const { isTablet } = useIsDeviceType();
  const isLaptop = !isTablet;
  const auth = useAuth();

  return (
    <>
      {isTablet && <MobileHeader />}

      {isLaptop && !auth.isSignedIn && <PublicLaptopHeader />}

      {isLaptop && auth.isSignedIn && <PrivateLaptopHeader />}
    </>
  );
}
